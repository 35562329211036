import React from 'react'
import Navibar from '../Components/Navibar'
import Hero from '../Components/Hero'
import Footeri from '../Components/Footer'
import Recruit from '../Components/Recruit'
function Home() {
  return (
    <div>
    <Navibar/>
    <Recruit/>
    <Hero/>
    <Footeri/>
    </div>
  )
}

export default Home